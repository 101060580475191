import InputBase from "@material-ui/core/InputBase";
import { fade, withStyles } from "@material-ui/core/styles";

const InputText = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.greyBorder}`,
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  error: {
    "& .MuiInputBase-input": {
      borderColor: theme.palette.error.main,
    },
    "& .MuiInputBase-input:focus": {
      boxShadow: `${fade(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.error.main,
    },
  },
}))(InputBase);

export default InputText;
