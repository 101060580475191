import React from "react";
import { Route, Switch } from "react-router-dom";

import Wallet from "./Wallet";
import History from "./History";
import WalletCreate from "./WalletCreate";
import HistoryDetail from "./HistoryDetail";
import TransactionCreate from "./TransactionCreate";

const Home = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Wallet />
      </Route>
      <Route exact path="/wallet">
        <Wallet />
      </Route>
      <Route exact path="/wallet/create">
        <WalletCreate />
      </Route>
      <Route exact path="/wallet/transaction/create/:walletAddress">
        <TransactionCreate />
      </Route>
      <Route exact path="/wallet/history/:walletAddress">
        <History />
      </Route>
      <Route exact path="/wallet/transaction/:txHash/:walletAddress">
        <HistoryDetail />
      </Route>
    </Switch>
  );
};

export default Home;
