import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { ThemeProvider as JssThemeProvider } from "react-jss";

import CssBaseLine from "./CssBaseline";
import theme from "./theme";

type ThemeProvider = Omit<
  React.ComponentProps<typeof MuiThemeProvider>,
  "theme"
>;

const ThemeProvider: React.FC<ThemeProvider> = ({
  children = null,
  ...other
}) => {
  return (
    <MuiThemeProvider theme={theme} {...other}>
      <JssThemeProvider theme={theme} {...other}>
        <CssBaseLine />
        {children}
      </JssThemeProvider>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
