import Axios from "axios";
import { Notification } from "../components";

const tokenKey = "UNI_AUTHENTICATION_TOKEN";

export const api = Axios.create({
  baseURL: "https://api-prod.unifinance.io",
  // baseURL: "http://localhost:3030",
});

api.interceptors.response.use(
  (response) => {
    if (typeof response.data === "object" && response.data.error) {
      Notification.error({
        message: response.data.error?.message,
      });
      throw new Error(response.data.error?.message);
    }
    return response;
  },
  (error) => {
    if (error?.response) {
      if (error.response?.data?.message) {
        Notification.error({
          message: error.response.data.message,
          description: error.response.data.title,
        });
      }
    } else if (error.message) {
      Notification.error({
        message: error.message,
      });
    }
    return Promise.reject(error);
  }
);

export const getAuthToken = (): string | undefined => {
  return window.localStorage.getItem(tokenKey) || undefined;
};

export const setAuthToken = (token: string): void => {
  window.localStorage.setItem(tokenKey, token);
  api.defaults.headers.common = { authorization: `Bearer ${token}` };
};

export const deleteAuthToken = (): void => {
  delete api.defaults.headers.common["authorization"];
  window.localStorage.removeItem(tokenKey);
};
