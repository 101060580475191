import { Box } from "@material-ui/core";
import React from "react";

type ChainStatusProps = {
  value: string;
} & React.ComponentProps<typeof Box>;

const ChainStatus: React.FC<ChainStatusProps> = ({ value, ...rest }) => {
  return <Box {...rest}>{value}</Box>;
};

export default ChainStatus;
