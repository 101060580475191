import React from "react";
import { BaseProps } from "../types";

const EthIcon: React.FC<BaseProps> = ({ ...rest }) => {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" {...rest}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-387.000000, -270.000000)">
          <g transform="translate(387.000000, 270.000000)">
            <circle fill="#5D7CF2" cx="24" cy="24" r="24"></circle>
            <polygon
              fill="#BFCCFB"
              points="23.7857143 4.5 13.5 21.9842213 23.7857143 28.1955453 34.0714286 21.9842213"
            ></polygon>
            <polygon
              fill="#FFFFFF"
              points="13.5 23.8668326 23.7857143 30.1773944 23.7857143 38.7857143"
            ></polygon>
            <polygon
              fill="#BFCCFB"
              transform="translate(28.928571, 31.326273) scale(-1, 1) translate(-28.928571, -31.326273) "
              points="23.7857143 23.8668326 34.0714286 30.1773944 34.0714286 38.7857143"
            ></polygon>
            <polygon
              fill="#FFFFFF"
              points="23.7857143 4.5 13.5 21.9842213 23.7857143 28.1955453"
            ></polygon>
            <polygon
              fill="#BFCCFB"
              points="23.7857143 17.1882319 13.5 21.9842213 23.7857143 28.1955453 34.0714286 21.9842213"
            ></polygon>
            <polygon
              fill="#7D97F5"
              points="23.7857143 17.1882319 23.7857143 28.1955453 34.0714286 21.9842213"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EthIcon;
