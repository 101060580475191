import React from "react";
import { Box } from "../../components";
import { Logo } from "../../icons";
import { getAuthToken } from "../../services";
import Account from "./Account";
import Menu from "./Menu";

const Header: React.FC = () => {
  const token = getAuthToken();
  return (
    <Box
      display="flex"
      borderBottom={1}
      color="common.greyBorder"
      justifyContent="space-between"
      alignItems="center"
      py={2}
      px={8}
      style={{ background: "#ffffff" }}
      component="header"
    >
      <Box>
        <Logo />
      </Box>
      <Box display="flex">
        {token && <Menu />}
        <Account />
      </Box>
    </Box>
  );
};

export default Header;
