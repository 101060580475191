import AntdTable from "antd/es/table";
import "antd/es/pagination/style/index.css";
import clsx from "clsx";
import React from "react";
import useStyle from "./useTableStyle";

type TableProps = {
  isPannel: boolean;
  hasHover?: boolean;
} & React.ComponentProps<typeof AntdTable>;

const Table: React.FC<TableProps> = ({
  isPannel,
  hasHover,
  className,
  ...rest
}) => {
  const classes = useStyle();

  return (
    <AntdTable
      showSorterTooltip={false}
      className={clsx(
        classes.root,
        {
          [classes.isPannel]: isPannel,
          [classes.hasHover]: hasHover,
        },
        className
      )}
      {...rest}
    />
  );
};

export default Table;
