import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Box } from "../../components";
import { HomeIcon, TransactionIcon } from "../../icons";
import { BaseProps } from "../../types";
import { VscKey } from "react-icons/vsc";
const Menu: React.FC<BaseProps> = ({ ...other }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      color="text.primary"
      alignItems="center"
      fontSize="body1.fontSize"
      component="nav"
    >
      <NavLink
        to="/wallet"
        className={classes.item}
        activeClassName={classes.activeNav}
      >
        <HomeIcon className={classes.itemIcon} />
        <Box className={classes.itemTitle}>{t("Home")}</Box>
      </NavLink>
      <NavLink
        to="/transaction"
        className={classes.item}
        activeClassName={classes.activeNav}
      >
        <TransactionIcon className={classes.itemIcon} />
        <Box className={classes.itemTitle}>{t("Transaction")}</Box>
      </NavLink>
      <NavLink
        to="/authorization"
        className={classes.item}
        activeClassName={classes.activeNav}
      >
        <VscKey size={18} />
        <Box className={classes.itemTitle}>{t("Authorization")}</Box>
      </NavLink>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    alignItems: "center",
    color: "inherit",
    marginRight: theme.spacing(6),
    "&$activeNav $itemIcon": {
      fill: theme.palette.common.linkHover,
    },

    "&$activeNav $itemTitle": {
      color: theme.palette.common.linkHover,
    },
  },
  activeNav: {},
  itemTitle: {
    marginLeft: theme.spacing(1),
  },
  itemIcon: {
    fill: theme.palette.text.primary,
  },
}));

export default Menu;
