import { Button } from "@material-ui/core";
import { ButtonSpinnerIcon } from "../../icons";
import React from "react";
import { Box } from "../";

type ButtonPrimaryProps = {
  loading?: boolean;
} & React.ComponentProps<typeof Button>;

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  loading = false,
  disabled,
  children,
  ...rest
}) => {
  return (
    <Button
      disabled={loading || disabled}
      variant="contained"
      color="primary"
      {...rest}
    >
      {loading ? (
        <Box pr={1} style={{ position: "relative", top: 1 }}>
          <ButtonSpinnerIcon />
        </Box>
      ) : null}
      {children}
    </Button>
  );
};

export default ButtonPrimary;
