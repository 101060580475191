import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { Box } from "../";
import { BtcIcon, DaiIcon, EthIcon, UsdtIcon } from "../../icons";
import { BaseProps } from "../../types";

const iconMap = {
  btc: BtcIcon,
  eth: EthIcon,
  dai: DaiIcon,
  usdt: UsdtIcon,
} as Record<string, typeof BtcIcon>;

type AssetIconProps = BaseProps & {
  coinType: string;
};

const AssetIcon: React.FC<AssetIconProps> = ({
  coinType,
  className,
  ...rest
}) => {
  const classes = useStyles();

  const Component = iconMap[coinType];

  if (!Component) return null;

  return (
    <Box display="flex" className={clsx(className, classes.root)} {...rest}>
      <Component style={{ height: "100%", width: "100%" }} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 48,
    height: 48,
  },
}));

export default AssetIcon;
