import React from "react";
import { BaseProps } from "../types";

const BtcIcon: React.FC<BaseProps> = ({ ...rest }) => {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" {...rest}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-109.000000, -270.000000)" fillRule="nonzero">
          <g transform="translate(109.000000, 270.000000)">
            <path
              d="M47.2807487,29.8081376 C44.0771557,42.664327 31.0520589,50.4890196 18.1920859,47.2806124 C5.33211293,44.0722052 -2.48894526,31.0535314 0.719687842,18.196142 C3.92832095,5.33875261 16.9469376,-2.48954014 29.8040305,0.719347068 C42.6611235,3.92823427 50.4893818,16.9476281 47.2807487,29.8081376 L47.2807487,29.8081376 Z"
              fill="#F7931A"
            ></path>
            <path
              d="M33.5416131,20.2202974 C34.0070146,17.0261214 31.6350589,15.3088948 28.3912947,14.163437 L29.4447689,9.83676518 L26.8752282,9.18050329 L25.8489102,13.3933974 C25.1742185,13.2210506 24.4807984,13.0573452 23.7915921,12.8970003 L24.8216558,8.65626189 L22.2546903,8 L21.2042594,12.3249915 L16.0230391,11.0091072 L15.3394514,13.822584 C15.3394514,13.822584 17.2455374,14.2704936 17.2052713,14.2983379 C18.2456356,14.5647793 18.4343245,15.2704888 18.4024862,15.8302558 L15.5197125,27.6832961 C15.3923592,28.0073464 15.069762,28.4931818 14.3426307,28.307393 C14.3683823,28.345799 12.4754061,27.8273185 12.4754061,27.8273185 L11.2,30.8467473 L16.373729,32.1861552 L15.3104223,36.5632348 L17.8762173,37.2194967 L18.9296915,32.8894644 C19.6320077,33.0843747 20.3109133,33.2644026 20.9762408,33.4338689 L19.9269805,37.7437381 L22.4960529,38.4 L23.5593596,34.0313217 C27.9401736,34.8810536 31.2345046,34.5382804 32.6192379,30.4787701 C33.7363888,27.2082623 32.5644573,25.3215694 30.2594557,24.0906583 C31.9379912,23.6921964 33.2021603,22.5606607 33.5395062,20.2219776 M27.6702501,28.6597677 C26.8742918,31.9302755 21.5050849,30.1628811 19.7628726,29.7188121 L21.1735916,23.9207119 C22.9148675,24.3662211 28.498515,25.2485981 27.6711865,28.6592876 M28.4671448,20.1739702 C27.7428228,23.1504323 23.2721123,21.6381975 21.8211271,21.2670999 L23.0984061,16.0069232 C24.5498595,16.3785009 29.220496,17.0702883 28.465272,20.1737301"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BtcIcon;
