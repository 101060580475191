import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  AssetIcon,
  AssetName,
  Box,
  ButtonPrimary,
  NumberFormat,
  Pannel,
  Address,
} from "../../components";
import { chainTypeToCoinType } from "../../constants";
import { Wallet } from "../../services";

type RenderWalletItemProps = {
  wallet: Wallet;
  hideAction?: boolean;
};

const RenderWalletItem: React.FC<RenderWalletItemProps> = React.memo(
  ({ wallet, hideAction = false }) => {
    const { t } = useTranslation();

    const { chainType, address, walletType, name } = wallet;

    return (
      <Pannel>
        <Box display="flex" padding={3}>
          <Box width={200}>
            <Box display="flex" alignItems="center" pt={0.5}>
              <AssetIcon coinType={chainTypeToCoinType[chainType]} />
              <AssetName
                coinType={chainTypeToCoinType[chainType]}
                color="text.primary"
                fontSize="body1.fontSize"
                ml={2}
              />
            </Box>
          </Box>
          <Box flex="1">
            <Box
              color="text.primary"
              fontSize="body1.fontSize"
              lineHeight="1"
              mb={2}
            >
              {name}
            </Box>
            <Box
              color="text.secondary"
              fontSize="body2.fontSize"
              lineHeight="1"
              mb={3}
            >
              {address}
            </Box>
            {Object.entries(wallet.coins)
              // .filter(([coinType, balanceInfo]) => {
              //   if (chainTypeToCoinType[chainType] === "eth")
              //     if (coinType !== "btc") return true;
              //   if (coinType === "btc") return true;
              // })
              .map(([coinType, balanceInfo]) => (
                <Box
                  display="flex"
                  key={coinType}
                  justifyContent="space-between"
                  mr={-33}
                >
                  <Box
                    display="flex"
                    flex="1"
                    alignItems="center"
                    key={coinType}
                  >
                    <AssetIcon
                      coinType={coinType.toLowerCase()}
                      style={{ width: 32, height: 32 }}
                    />
                    <AssetName
                      coinType={coinType}
                      ml={1}
                      pr={2}
                      color="text.primary"
                      fontSize="body1.fontSize"
                    />
                    <Box
                      color="text.primary"
                      fontSize="h5.fontSize"
                      marginBottom={coinType ? "3px" : "1px"}
                    >
                      <NumberFormat value={balanceInfo.balance} mantissa={8} />
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>

          <Box
            display="grid"
            gridGap={24}
            gridTemplateColumns="repeat(2, 120px)"
            pt={0.75}
          >
            {walletType === "HOT" && !hideAction ? (
              <Box>
                <Link to={`/wallet/transaction/create/${address}`}>
                  <ButtonPrimary fullWidth>{t("SEND")}</ButtonPrimary>
                </Link>
              </Box>
            ) : null}
            {!hideAction ? (
              <Box>
                {walletType === "HOT" ? (
                  <Link to={`/wallet/history/${address}`}>
                    <ButtonPrimary fullWidth variant="outlined">
                      {t("HISTORY")}
                    </ButtonPrimary>
                  </Link>
                ) : (
                  <Address chainType={chainType} address={address}>
                    <ButtonPrimary fullWidth variant="outlined">
                      {t("HISTORY")}
                    </ButtonPrimary>
                  </Address>
                )}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Pannel>
    );
  }
);

export default React.memo(RenderWalletItem);
