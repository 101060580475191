import AntdDescriptions from "antd/es/descriptions";
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

type DescriptionsProps = {
  isPannel?: boolean;
} & React.ComponentProps<typeof AntdDescriptions>;

const Descriptions: React.FC<DescriptionsProps> = ({
  className,
  isPannel = false,
  bordered,
  column = 1,
  ...rest
}) => {
  const classes = useStyle();

  return (
    <AntdDescriptions
      column={column}
      bordered={isPannel || bordered}
      className={clsx(className, {
        [classes.isPannel]: isPannel,
      })}
      {...rest}
    />
  );
};

const useStyle = makeStyles((theme) => ({
  "@global": {
    ".ant-descriptions-title": {
      marginBottom: 20,
      color: theme.palette.text.secondary,
      fontWeight: "bold",
      fontSize: 16,
      lineHeight: "1.5715",
    },
    ".ant-descriptions-view": {
      width: "100%",
      overflow: "hidden",
      borderRadius: 2,
    },
    ".ant-descriptions-view table": {
      width: "100%",
      tableLayout: "fixed",
    },
    ".ant-descriptions-row > th, .ant-descriptions-row > td": {
      paddingBottom: 16,
    },
    ".ant-descriptions-row:last-child": {
      borderBottom: "none",
    },
    ".ant-descriptions-item-label": {
      color: theme.palette.text.secondary,
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "1.5715",
    },
    ".ant-descriptions-item-label::after": {
      position: "relative",
      top: -0.5,
      margin: "0 8px 0 2px",
      content: '" "',
    },
    ".ant-descriptions-item-colon::after": {
      content: '":"',
    },
    ".ant-descriptions-item-no-label::after": {
      margin: "0",
      content: '""',
    },
    ".ant-descriptions-item-content": {
      display: "table-cell",
      color: theme.palette.text.primary,
      fontSize: 14,
      lineHeight: "1.5715",
    },
    ".ant-descriptions-item": {
      paddingBottom: "0",
    },
    ".ant-descriptions-item > span": {
      display: "inline-block",
    },
    ".ant-descriptions-middle .ant-descriptions-row > th, .ant-descriptions-middle .ant-descriptions-row > td": {
      paddingBottom: 12,
    },
    ".ant-descriptions-small .ant-descriptions-row > th, .ant-descriptions-small .ant-descriptions-row > td": {
      paddingBottom: 8,
    },
    ".ant-descriptions-bordered .ant-descriptions-view": {
      // border: `1px solid ${theme.palette.common.greyBorder}`,
      // borderRadius: "8px",
    },
    ".ant-descriptions-bordered .ant-descriptions-view > table": {
      tableLayout: "auto",
    },
    ".ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content": {
      padding: "16px 24px",
      // borderRight: "1px solid rgba(0, 0, 0, 0.06)",
    },
    ".ant-descriptions-bordered .ant-descriptions-item-label:last-child, .ant-descriptions-bordered .ant-descriptions-item-content:last-child": {
      borderRight: "none",
    },
    ".ant-descriptions-bordered .ant-descriptions-item-label::after": {
      display: "none",
    },
    ".ant-descriptions-bordered .ant-descriptions-row": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
    },
    ".ant-descriptions-bordered .ant-descriptions-row:last-child": {
      borderBottom: "none",
    },
    ".ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-middle\n  .ant-descriptions-item-content": {
      padding: "12px 24px",
    },
    ".ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-small\n  .ant-descriptions-item-content": {
      padding: "8px 16px",
    },
  },
  isPannel: {
    "& .ant-descriptions-view": {
      background: theme.palette.common.white,
    },
    "& .ant-descriptions-item-label": {
      width: "33%",
    },
  },
}));

export default Descriptions;
