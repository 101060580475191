import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  AssetName,
  Box,
  Breadcrumbs,
  ChainStatus,
  Date,
  DescriptionItem,
  Descriptions,
  TxHash,
} from "../../../components";
import { useCoinInfo } from "../../../selectors";
import { queryTransaction, Transaction } from "../../../services";

const TransactionDetail: React.FC = () => {
  const { txHash, walletAddress } = useParams<{
    txHash: string;
    walletAddress: string;
  }>();

  const [data, setData] = useState<Transaction>();
  const coin = useCoinInfo(data?.coinType);
  useEffect(() => {
    queryTransaction(txHash, walletAddress).then((result) => {
      setData(result);
    });
  }, [txHash, walletAddress]);

  return (
    <Box>
      <Breadcrumbs style={{ marginTop: 32, marginBottom: 24 }}>
        <Link to="/transaction">
          <Box fontSize="h5.fontSize" color="text.secondary" component="span">
            Transactions
          </Box>
        </Link>
        <Box fontSize="h5.fontSize" color="primary.light">
          Detail
        </Box>
      </Breadcrumbs>

      <Descriptions isPannel={true}>
        <DescriptionItem label="txHash">{data?.txHash}</DescriptionItem>
        <DescriptionItem label="Amount">
          {data?.balanceChange &&
            parseInt(data?.balanceChange) / Math.pow(10, coin?.decimal || 1)}
        </DescriptionItem>
        <DescriptionItem label="Fee Amount">
          {data?.gas &&
            (parseInt(data?.gas) / Math.pow(10, coin?.decimal || 1)).toFixed(
              coin?.decimal || 10
            )}
        </DescriptionItem>
        <DescriptionItem label="Currency">
          {data && <AssetName coinType={data.coinType} />}
        </DescriptionItem>
        <DescriptionItem label="Status">
          {data && <ChainStatus value={data.txStatus} />}
        </DescriptionItem>
        <DescriptionItem label="Description">
          {data?.description}
        </DescriptionItem>
        <DescriptionItem label="Tx Hash">
          {data?.txHash && (
            <TxHash coinType={data.coinType} txHash={data.txHash}>
              {data.txHash}
            </TxHash>
          )}
        </DescriptionItem>
        <DescriptionItem label="Created Date">
          <Date value={data?.createdAt} />
        </DescriptionItem>
        <DescriptionItem label="Updated Date">
          <Date value={data?.updatedAt} />
        </DescriptionItem>
      </Descriptions>
    </Box>
  );
};

export default TransactionDetail;
