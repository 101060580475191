import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Menu, MenuItem } from "../../components";
import { UserIcon } from "../../icons";
import { BaseProps } from "../../types";
import { useFetchAccount } from "../../common";
import { deleteAuthToken } from "../../services";
import { useHistory } from "react-router-dom";

const PageAccount: React.FC<BaseProps> = ({ ...other }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data } = useFetchAccount();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    if (data) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    handleClose();
    deleteAuthToken();
    history.push("/login");
  };

  return (
    <Box>
      <Box
        ml={6}
        display="flex"
        alignItems="center"
        color="text.primary"
        fontSize="body1.fontSize"
        onClick={handleClick}
      >
        <Box mr={1}>{t(data?.firstName || "Account")}</Box>
        <IconButton>
          <UserIcon fontSize={"large"} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleSignout}>Sign Out</MenuItem>
      </Menu>
    </Box>
  );
};

export default PageAccount;
