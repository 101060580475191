import React from "react";
import { BaseProps } from "../types";

const AddIcon: React.FC<BaseProps> = ({ ...rest }) => {
  return (
    <svg width="26px" height="25px" viewBox="0 0 26 25" {...rest}>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g transform="translate(-100.000000, -110.000000)" stroke="#FFFFFF">
          <g transform="translate(101.000000, 110.000000)">
            <line x1="0" y1="12.5" x2="24" y2="12.5"></line>
            <line
              x1="0"
              y1="12.5"
              x2="24"
              y2="12.5"
              transform="translate(12.000000, 12.500000) rotate(-270.000000) translate(-12.000000, -12.500000) "
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AddIcon;
