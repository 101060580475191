import dayjs from "dayjs";
import React from "react";
import { Box } from "../";

type DateProps = {
  value?: number | string;
  format?: string;
} & React.ComponentProps<typeof Box>;

const Date: React.FC<DateProps> = ({
  value,
  format = "YYYY-MM-DD HH:mm:ss",
  ...rest
}) => {
  if (!value) return null;
  return <Box {...rest}>{dayjs(value).format(format)}</Box>;
};

export default Date;
