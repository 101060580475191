import React, { useCallback, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  ButtonPrimary,
  FormControl,
  InputText,
  MenuItem,
  Notification,
  Select,
  Slider,
  FormHelperText,
} from "../../../components";
import { useCoins } from "../../../selectors";
import {
  Fee,
  queryGasBtcFee,
  queryGasEthFee,
  transfer,
  Wallet,
} from "../../../services";
import { useForm, Controller } from "react-hook-form";
import { chainTypeToCoinType } from "../../../constants";

type RenderTransactionCreateFormProps = {
  wallet: Wallet;
};

const RenderTransactionCreateForm: React.FC<RenderTransactionCreateFormProps> = ({
  wallet,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const coins = useCoins();
  const { chainType: walletChainType } = wallet;
  const [submitting, setSubmitting] = useState(false);
  const [coinType, setCoinType] = useState<string>();
  const [feeRange, setFeeRange] = useState<Fee>();
  const [feePrice, setFeePrice] = useState<number>();

  const ethCoins = coins.filter((coin) => coin?.shortName !== "BTC");
  const btcCoins = coins.filter((coin) => coin?.shortName === "BTC");

  const walletCoins = walletChainType === "bitcoin" ? btcCoins : ethCoins;

  console.log(walletCoins);

  const { handleSubmit, errors, control } = useForm();

  const defaultWalletType = walletChainType === "bitcoin" ? "BTC" : "ETH";

  useLayoutEffect(() => {
    switch (defaultWalletType) {
      case "BTC":
        queryGasBtcFee().then((result) => {
          setFeeRange(result);
          setFeePrice(Number(result.average));
        });
        break;
      case "ETH":
        queryGasEthFee().then((result) => {
          setFeeRange(result);
          setFeePrice(Number(result.average));
        });
        break;
    }
  }, [coinType, defaultWalletType]);

  const submit = useCallback(
    async ({ amount, toAddress, customerId, description, externalRef }) => {
      if (!coinType || !feePrice || !wallet) return;

      setSubmitting(true);

      try {
        await transfer({
          fromAddress: wallet.address,
          toAddress,
          amount,
          customerId,
          coinType,
          gasPrice: String(feePrice),
          description,
          externalRef,
        });

        Notification.success({
          message: "Authorization Request",
          description: "Success",
        });

        setSubmitting(false);
        history.push("/wallet");
      } catch {
        setSubmitting(false);
      }
    },
    [history, wallet, coinType, feePrice]
  );

  return (
    <Box
      display="grid"
      gridColumnGap={192}
      gridRowGap={24}
      gridTemplateColumns="1fr 1fr"
    >
      <Box>
        <Box fontSize="body1.fontSize" color="text.primary" mb={1}>
          From
        </Box>
        <FormControl variant="outlined" fullWidth>
          <InputText
            disabled={true}
            value={wallet.address || ""}
            onChange={() => {}}
          />
        </FormControl>
      </Box>
      <Box>
        <Box fontSize="body1.fontSize" color="text.primary" mb={1}>
          Amount
        </Box>
        <FormControl variant="outlined" fullWidth error={!!errors.amount}>
          <Controller
            name="amount"
            render={({ onChange, value }) => (
              <InputText value={value} type="number" onChange={onChange} />
            )}
            control={control}
            defaultValue=""
            rules={{ required: true }}
          />
          {errors.amount?.type === "required" && (
            <FormHelperText>Required</FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box gridRow="2">
        <Box fontSize="body1.fontSize" color="text.primary" mb={1}>
          To
        </Box>
        <FormControl variant="outlined" fullWidth error={!!errors.toAddress}>
          <Controller
            name="toAddress"
            render={({ onChange, value }) => (
              <InputText value={value} onChange={onChange} />
            )}
            control={control}
            defaultValue=""
            rules={{ required: true }}
          />
          {errors.toAddress?.type === "required" && (
            <FormHelperText>Required</FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box display="flex" justifyContent="center" flexDirection="column">
        <Box display="flex">
          <Box fontSize="body1.fontSize" color="text.primary" mb={1} mr={2}>
            Fee Price
          </Box>
          <Box
            fontSize="body1.fontSize"
            color="text.primary"
            fontWeight={500}
            mb={1}
          >
            {feePrice || "---"}{" "}
            {feePrice
              ? coinType === "btc"
                ? "satoshi"
                : coinType === "eth"
                ? "gwei"
                : null
              : null}
          </Box>
        </Box>
        <Box>
          <Slider
            track={false}
            value={Math.floor(
              !feePrice || !feeRange?.safeLow || !feeRange?.fastest
                ? 0
                : ((feePrice - feeRange.safeLow) /
                    (feeRange.fastest - feeRange.safeLow)) *
                    100
            )}
            disabled={!feeRange}
            onChange={(_, value) => {
              if (!feeRange?.safeLow || !feeRange?.fastest) return;
              setFeePrice(
                Math.floor(
                  feeRange.safeLow +
                    ((feeRange.fastest - feeRange.safeLow) * (value as any)) /
                      100
                )
              );
            }}
            marks={[
              {
                value: 0,
                label: `Safe: ${feeRange?.safeLow || "---"}`,
              },
              {
                value: 100,
                label: `Fast: ${feeRange?.fastest || "---"}`,
              },
            ]}
          />
        </Box>
      </Box>

      <Box>
        <Box fontSize="body1.fontSize" color="text.primary" mb={1}>
          Currency
        </Box>
        <FormControl variant="outlined" fullWidth>
          <Select
            value={coinType || defaultWalletType}
            onChange={(events) => setCoinType(events.target.value as string)}
          >
            {walletCoins.map(({ shortName }) => (
              <MenuItem key={shortName} value={shortName}>
                {shortName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box>
        <Box fontSize="body1.fontSize" color="text.primary" mb={1}>
          Customer Id
        </Box>
        <FormControl variant="outlined" fullWidth error={!!errors.customerId}>
          <Controller
            name="customerId"
            render={({ onChange, value }) => (
              <InputText value={value} onChange={onChange} />
            )}
            control={control}
            defaultValue=""
            rules={{ required: true }}
          />
          {errors.customerId?.type === "required" && (
            <FormHelperText>Required</FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box>
        <Box fontSize="body1.fontSize" color="text.primary" mb={1}>
          External Transaction
        </Box>
        <FormControl variant="outlined" fullWidth error={!!errors.externalRef}>
          <Controller
            name="externalRef"
            render={({ onChange, value }) => (
              <InputText value={value} onChange={onChange} />
            )}
            control={control}
            defaultValue=""
            rules={{ required: true }}
          />
          {errors.externalRef?.type === "required" && (
            <FormHelperText>Required</FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box>
        <Box fontSize="body1.fontSize" color="text.primary" mb={1}>
          Description
        </Box>
        <FormControl variant="outlined" fullWidth error={!!errors.description}>
          <Controller
            name="description"
            render={({ onChange, value }) => (
              <InputText value={value} onChange={onChange} />
            )}
            control={control}
            defaultValue=""
            rules={{ required: true }}
          />
          {errors.description?.type === "required" && (
            <FormHelperText>Required</FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="1fr 96px 96px"
        gridGap={24}
        gridColumn="span 2"
      >
        <Box></Box>
        <Link to="/wallet">
          <ButtonPrimary fullWidth variant="outlined">
            {t("Cancel")}
          </ButtonPrimary>
        </Link>
        <ButtonPrimary loading={submitting} onClick={handleSubmit(submit)}>
          {t("Confirm")}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

export default RenderTransactionCreateForm;
