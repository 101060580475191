import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import {} from "../../../common";
import {
  Pannel,
  Box,
  Divider,
  NumberFormat,
  AssetIcon,
  AssetName,
} from "../../../components";
import { ExchangeRates } from "../../../services";

type RenderBalanceItemProps = {
  coinType: string;
  hideDivider?: boolean;
  value?: number;
  rate?: number;
};

const RenderBalanceItem: React.FC<RenderBalanceItemProps> = React.memo(
  ({ coinType, hideDivider = false, value, rate }) => {
    const usdAmount =
      rate === undefined || value === undefined ? undefined : rate * value;

    return (
      <Box display="flex" flex={1}>
        {!hideDivider && (
          <Divider
            orientation="vertical"
            style={{ height: 56, alignSelf: "flex-end" }}
          />
        )}
        <AssetIcon
          coinType={coinType}
          style={{ marginTop: 28, marginLeft: 24, marginRight: 16 }}
        />
        <Box flex="1">
          <AssetName
            coinType={coinType}
            color="text.primary"
            lineHeight="1.5"
            fontSize="body2.fontSize"
          />
          <Box color="text.primary" lineHeight="1.5" fontSize="h5.fontSize">
            <NumberFormat value={value} />
          </Box>
          <Box
            color="text.secondary"
            lineHeight="1.5"
            fontSize="body1.fontSize"
          >
            <NumberFormat prefix="≈ US $" value={usdAmount} mantissa={2} />
          </Box>
        </Box>
      </Box>
    );
  }
);

type RenderWalletBalanceProps = {
  data?: { [coinType: string]: number };
  rates?: ExchangeRates;
};

const RenderWalletBalance: React.FC<RenderWalletBalanceProps> = ({
  data,
  rates,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Pannel className={classes.root}>
      <Box color="text.primary" fontSize="h6.fontSize" mb={3}>
        {t("Wallet Balance")}
      </Box>
      <Box display="flex" mx="-24px" alignItems="center">
        {data &&
          Object.entries(data).map(
            (entry) =>
              rates && (
                <RenderBalanceItem
                  value={entry[1]}
                  key={entry[0]}
                  rate={rates[entry[0].toUpperCase()]}
                  coinType={entry[0]}
                  {...(entry[0] === "btc" && { hideDivider: true })}
                />
              )
          )}
      </Box>
    </Pannel>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

export default React.memo(RenderWalletBalance);
