import React from "react";
import { BaseProps } from "../types";

const UsdtIcon: React.FC<BaseProps> = ({ ...rest }) => {
  return (
    <svg width="50px" height="50px" viewBox="0 0 50 50" {...rest}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-943.000000, -269.000000)" fill="#000000">
          <g transform="translate(944.000000, 270.000000)">
            <path
              d="M24,0 C10.745166,0 1.70530257e-13,10.745166 1.70530257e-13,24 C1.70530257e-13,37.254834 10.745166,48 24,48 C37.254834,48 48,37.254834 48,24 C48,10.745166 37.254834,0 24,0 Z M24,0.912547529 C36.7508479,0.912547529 47.0874525,11.2491521 47.0874525,24 C47.0874525,36.7508479 36.7508479,47.0874525 24,47.0874525 C11.2491521,47.0874525 0.912547529,36.7508479 0.912547529,24 C0.912547529,11.2491521 11.2491521,0.912547529 24,0.912547529 Z"
              stroke="#000000"
              strokeWidth="1.5"
              fillRule="nonzero"
            ></path>
            <path d="M34.2212389,10.5 L34.2212389,15.6573034 L26.264,15.657 L26.2651305,19.084329 C32.6870665,19.3902953 37.5,20.6535008 37.5,22.1653291 C37.5,23.6827874 32.6511529,24.9497677 26.1933177,25.2497075 L26.1924779,36 L20.3915929,36 L20.3906319,25.253548 C13.8909149,24.9607314 9,23.6893558 9,22.1653291 C9,20.639191 13.9044754,19.3664046 20.4176538,19.0758988 L20.418,15.657 L12.3628319,15.6573034 L12.3628319,10.5 L34.2212389,10.5 Z M10.3451327,21.8378812 C10.3451327,23.2168201 16.1228386,24.3346709 23.25,24.3346709 C30.3771614,24.3346709 36.1548673,23.2168201 36.1548673,21.8378812 C36.1548673,20.6598253 31.9379294,19.6723281 26.2652343,19.4096134 L26.2644231,23.2001266 C25.1138363,23.2303529 24.2156525,23.24684 23.5698717,23.2495878 L23.3837096,23.25 C22.7288794,23.25 21.7403993,23.2333755 20.4182692,23.2001266 L20.4178564,19.4014081 C14.6530453,19.6511598 10.3451327,20.6471856 10.3451327,21.8378812 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default UsdtIcon;
