import React from "react";

import { BaseProps } from "../../types";
import { useCoins } from "../../selectors";
import { ExternalLink } from "..";

type AddressProps = React.PropsWithChildren<
  {
    chainType?: string;
    address?: string;
  } & BaseProps
>;

const Address: React.FC<AddressProps> = ({
  chainType,
  address,
  children,
  ...other
}) => {
  const coinInfo = useCoins();
  if (!chainType) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  const coin = coinInfo?.find((coin) => coin.name === chainType.split("-")[0]);

  if (!coin) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <ExternalLink to={`${coin.addressUrl}${address}`} {...other}>
      {children}
    </ExternalLink>
  );
};

export default React.memo(Address);
