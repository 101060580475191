import { useCallback, useState } from "react";
import { queryAccount, queryWallets, queryAllCoins, Wallet } from "../services";
import { Account, Coin, WalletItem } from "../services";
import create, { SetState, State } from "./createState";

interface ApiState extends State {
  isAuth: boolean;
  "/api/account"?: Account;
  "/api/wallets"?: WalletItem[];
  setState: SetState<ApiState>;
}

export const [useApi] = create<ApiState>(
  (set: SetState<ApiState>): ApiState => ({
    isAuth: false,
    setState: set,
  })
);

export const useFetcher = <T>(key: string, _fetcher: any) => {
  const setState = useApi((state) => state.setState);
  const data = useApi<T | undefined>((state) => state[key]);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetcher = useCallback(
    async (...args) => {
      if (!key) return;
      try {
        setLoading(true);
        const result = await (_fetcher as any)(...args);
        setState((state) => {
          state[key] = result;
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [setState, key, _fetcher]
  );

  return {
    data,
    error,
    loading,
    fetcher,
  };
};

export const useFetchAccount = () => {
  return useFetcher<Account>("/api/account", queryAccount);
};

export const useFetchAllCoins = () => {
  return useFetcher<Coin[]>("/api/coins", queryAllCoins);
};

export const useFetchWallets = () => {
  return useFetcher<Wallet[]>("/api/wallets", queryWallets);
};

export default useApi;
