import React from "react";
import { BaseProps } from "../types";

const HomeIcon: React.FC<BaseProps> = ({ ...rest }) => {
  return (
    <svg height="18px" viewBox="0 0 20 18" fill="currentcolor" {...rest}>
      <path d="M16.2721893,0 L3.69822485,0 C1.66420118,0 0,1.66420118 0,3.69822485 L0,14.0532544 C0,16.0872781 1.66420118,17.7514793 3.69822485,17.7514793 L16.2721893,17.7514793 C18.306213,17.7514793 19.9704142,16.0872781 19.9704142,14.0532544 L19.9704142,3.69822485 C19.9704142,1.66420118 18.306213,0 16.2721893,0 Z M18.4911243,11.0946746 L11.8343195,11.0946746 C10.6139053,11.0946746 9.61538462,10.0961538 9.61538462,8.87573964 C9.61538462,7.65532544 10.6139053,6.65680473 11.8343195,6.65680473 L18.4911243,6.65680473 L18.4911243,11.0946746 Z M11.8343195,5.17751479 C9.80029586,5.17751479 8.13609467,6.84171598 8.13609467,8.87573964 C8.13609467,10.9097633 9.80029586,12.5739645 11.8343195,12.5739645 L18.4911243,12.5739645 L18.4911243,14.0532544 C18.4911243,15.2736686 17.4926036,16.2721893 16.2721893,16.2721893 L3.69822485,16.2721893 C2.47781065,16.2721893 1.47928994,15.2736686 1.47928994,14.0532544 L1.47928994,3.69822485 C1.47928994,2.47781065 2.47781065,1.47928994 3.69822485,1.47928994 L16.2721893,1.47928994 C17.4926036,1.47928994 18.4911243,2.47781065 18.4911243,3.69822485 L18.4911243,5.17751479 L11.8343195,5.17751479 Z"></path>
      <path d="M11.0946746,8.87573964 C11.0946746,9.28423428 11.4258249,9.61538462 11.8343195,9.61538462 C12.2428142,9.61538462 12.5739645,9.28423428 12.5739645,8.87573964 C12.5739645,8.46724501 12.2428142,8.13609467 11.8343195,8.13609467 C11.4258249,8.13609467 11.0946746,8.46724501 11.0946746,8.87573964 Z"></path>
    </svg>
  );
};

export default HomeIcon;
