import { CircularProgress } from "@material-ui/core";

import React from "react";

type SpinnerProps = React.ComponentProps<typeof CircularProgress>;

const Spinner: React.FC<SpinnerProps> = ({ ...rest }) => {
  return <CircularProgress thickness={2} {...rest} />;
};

export default Spinner;
