import { api } from "./ApiService";

export interface AuthorizationStep {
  id: string;
  authorityId: string;
  requesterId: string;
  nextId: string;
  statusId: string;
  payload: object;
}

export const queryAuthorizationSteps = async (): Promise<
  AuthorizationStep[]
> => {
  const res = await api.get("/authorization/pending");
  return res.data.data;
};

export const queryAuthorizationRequests = async (): Promise<
  AuthorizationStep[]
> => {
  const res = await api.get("/authorization/requests");
  return res.data.data;
};

export const updateAuthorizationStep = async (
  authorizationId: string,
  status: string
) => {
  return (await api.post(`/authorization/${authorizationId}`, { status })).data;
};
