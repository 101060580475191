import React, { useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFetchAccount } from "../../common";
import { deleteAuthToken, getAuthToken, setAuthToken } from "../../services";
import LayoutSpinner from "./LayoutSpinner";

const Auth: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const history = useHistory();

  const { data, error, fetcher } = useFetchAccount();

  useLayoutEffect(() => {
    const isFailAuth = error?.response?.status === 401;

    if (isFailAuth) {
      deleteAuthToken();
    }

    const authToken = getAuthToken();

    if (!authToken) {
      history.push("/login");
    }
  }, [history, error]);

  useLayoutEffect(() => {
    const authToken = getAuthToken();

    if (authToken) {
      setAuthToken(authToken);
      fetcher();
    } else {
      deleteAuthToken();
    }
  }, [fetcher]);

  return data ? <React.Fragment>{children}</React.Fragment> : <LayoutSpinner />;
};

export default Auth;
