import React from "react";
import { Switch, Route } from "react-router-dom";
import Authorization from "./Authorization";

import Home from "./Home";
import Login from "./Login";
import Transaction from "./Transaction";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/wallet">
        <Home />
      </Route>
      <Route path="/transaction">
        <Transaction />
      </Route>
      <Route exact path="/authorization">
        <Authorization />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
};

export default Routes;
