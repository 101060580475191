import React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  ButtonPrimary,
  Chip,
  Table,
  Notification,
} from "../../components";
import {
  AuthorizationStep,
  updateAuthorizationStep,
} from "../../services/Authorization";

type Action = {
  initiator: string;
  description: string;
  externalRef: string;
  action: string;
  fromAddress: string;
  toAddress: string;
};

type AuthorizationTableProps = {
  data: AuthorizationStep[];
  actionable?: boolean;
};

const AuthorizationTable = (props: AuthorizationTableProps) => {
  const { data, actionable = false } = props;
  const history = useHistory();
  const columns = [
    {
      title: "Initiator",
      dataIndex: "requesterId",
      render: (value: string) => value,
    },
    {
      title: "Description",
      dataIndex: ["payload", "description"],
      render: (value: string) => value,
    },
    {
      title: "Customer ID",
      dataIndex: ["payload", "customerId"],
      render: (value: string) => value,
    },
    {
      title: "External Ref",
      dataIndex: ["payload", "externalRef"],
      render: (value: string) => value,
    },
    {
      title: "Action",
      dataIndex: "actionId",
      render: (value: string) => (
        <Chip
          label={value[0].toUpperCase() + value.slice(1)}
          style={{
            backgroundColor: value === "internal" ? "lightgreen" : "salmon",
            padding: "3px 0px 3px 0px",
          }}
        />
      ),
    },
    {
      title: "From Address",
      dataIndex: ["payload", "fromAddress"],
      render: (value: string) => value,
    },
    {
      title: "To Address",
      dataIndex: ["payload", "toAddress"],
      render: (value: string) => value,
    },
    {
      title: "Status",
      dataIndex: "statusId",
      render: (value: string) => (
        <Chip
          label={value[0].toUpperCase() + value.slice(1)}
          style={{
            backgroundColor: value === "completed" ? "green" : "orange",
            padding: "3px 0px 3px 0px",
          }}
        />
      ),
    },
    {
      title: "",
      dataIndex: "id",
      render: (id: string, row: any) =>
        actionable && (
          <ButtonPrimary
            onClick={async () => {
              const res = await updateAuthorizationStep(row.id, "completed");
              if (res.blockHash)
                Notification.success({
                  message: "Transaction Authorized",
                  description:
                    "The transaction has been broadcast to the chain.",
                });
              history.go(0);
            }}
          >
            Accept
          </ButtonPrimary>
        ),
    },
  ];
  return (
    <Table
      columns={columns}
      isPannel={true}
      rowKey="initiator"
      dataSource={data}
      pagination={false}
    />
  );
};

export default AuthorizationTable;
