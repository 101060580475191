import React from "react";

import { BaseProps } from "../../types";
import { useCoinInfo } from "../../selectors";
import { ExternalLink } from "../";
import { Coin } from "../../services";

type TxHashProps = React.PropsWithChildren<
  {
    coinType: string;
    txHash: string;
  } & BaseProps
>;

const TxHash: React.FC<TxHashProps> = ({
  coinType,
  txHash,
  children,
  ...other
}) => {
  const coinInfo = useCoinInfo(coinType) as Coin;

  if (!coinInfo) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <ExternalLink to={`${coinInfo.transactionUrl}${txHash}`} {...other}>
      {children}
    </ExternalLink>
  );
};

export default React.memo(TxHash);
