export const TITLE = "UNI";

export const TOKEN_NAMES = ["BTC", "ETH", "DAI", "USDT"] as const;

export const WALLET_TYPE = [
  [0, "COLD"],
  [1, "HOT"],
];

export const chainTypeToCoinType = {
  bitcoin: "btc",
  ethereum: "eth",
  "bitcoin-testnet": "btc",
  "ethereum-rinkeby": "eth",
};

export const LEDGER_TYPE = ["ETHER", "BTC"] as const;

export const CURRENCY_TYPE = ["ETHER", "DAI", "USDT", "BTC", "OMNI"] as const;
