import React, { useCallback, useState } from "react";
import { Box, Spinner, Tab, Tabs } from "../../../components";
import { Wallet, WalletItem } from "../../../services";
import RenderWalletItem from "../RenderWalletItem";

type RenderWalletsProps = {
  wallets?: Wallet[];
};

const RenderWallets: React.FC<RenderWalletsProps> = ({ wallets }) => {
  const [tabKey, setTabKey] = useState("HOT");

  const handleChange = useCallback((_, newValue) => {
    setTabKey(newValue);
  }, []);

  return (
    <Box pt={3}>
      <Tabs value={tabKey} onChange={handleChange}>
        <Tab label={"Hot"} value={"HOT"} />
        <Tab label={"Cold"} value={"COLD"} />
      </Tabs>

      <Box display="grid" gridGap={24} my={3}>
        {wallets
          ?.filter(({ walletType }) => walletType === tabKey)
          .map((item) => {
            return <RenderWalletItem wallet={item} key={item.address} />;
          }) || (
          <Box mt={15} display="flex" justifyContent="center">
            <Spinner />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(RenderWallets);
