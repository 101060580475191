import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  AssetName,
  Box,
  ButtonPrimary,
  ChainStatus,
  Ellipsis,
  Table,
  TxHash,
} from "../../../components";
import { queryTransactions, Transaction } from "../../../services";

const TransactionList: React.FC = () => {
  const { t } = useTranslation();

  const [transactions, setTransactions] = useState<Transaction[]>();
  const [nextPage, setNextPage] = useState<object | null>(null);
  const [sorter, setSorter] = useState<string>();
  const goNextPage = (page?: object | null) => {
    queryTransactions({ page: page !== undefined ? page : nextPage }).then(
      ({ data, pagination }) => {
        setNextPage(pagination.next);
        setTransactions((transactions) => data);
      }
    );
  };

  useEffect(() => {
    queryTransactions({ page: nextPage }).then(({ data, pagination }) => {
      setNextPage(pagination.next);
      setTransactions((transactions) => data);
    });
  }, []);

  const columns = useMemo<any>(() => {
    return [
      {
        title: "txHash",
        dataIndex: "txHash",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        sorter: true,
        render: (value: string) => value,
      },
      {
        title: "Currency",
        sorter: true,
        dataIndex: "coinType",
        render: (value: string) => <AssetName coinType={value} />,
      },
      {
        title: "Status",
        sorter: true,
        dataIndex: "status",
        render: (value: string) => <ChainStatus value={value} />,
      },
      {
        title: "Tx Hash",
        dataIndex: "txHash",
        render: (value: string, { coinType }: { coinType: string }) => (
          <TxHash txHash={value} coinType={coinType}>
            <Ellipsis maxWidth={150}>{value}</Ellipsis>
          </TxHash>
        ),
      },
      {
        title: "",
        dataIndex: "txHash",
        align: "right",
        render: (txHash: string, row: Transaction) => (
          <Link to={`/transaction/${txHash}/${row.walletAddress}`}>
            <ButtonPrimary>{t("View")}</ButtonPrimary>
          </Link>
        ),
      },
    ];
  }, [t]);

  const onTableChange = useCallback(({ current }, _, { field, order }) => {
    setNextPage(current);

    if (!field || !order) {
      setSorter(undefined);
    } else {
      setSorter(
        `${field},${
          order === "ascend" ? "asc" : order === "descend" ? "desc" : undefined
        }`
      );
    }
  }, []);

  return (
    <Box>
      <Box color="text.primary" fontSize="h5.fontSize" mt={4} mb={3}>
        {t("Transactions")}
      </Box>
      <Table
        columns={columns}
        isPannel={true}
        rowKey="id"
        scroll={{
          x: true,
        }}
        onChange={onTableChange}
        dataSource={transactions}
        pagination={false}
      />
      <div
        style={{
          flexDirection: "row-reverse",
          width: "100%",
          display: "flex",
          marginTop: "1rem",
        }}
      >
        <ButtonPrimary
          disabled={transactions && transactions.length < 50}
          onClick={() => goNextPage()}
        >
          Next Page
        </ButtonPrimary>
        <ButtonPrimary
          style={{ backgroundColor: "gray", marginRight: "1rem" }}
          onClick={() => {
            goNextPage(null);
          }}
        >
          First Page
        </ButtonPrimary>
      </div>
    </Box>
  );
};

export default TransactionList;
