import React, { useLayoutEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  ChainStatus,
  Date,
  DescriptionItem,
  Descriptions,
  TxHash,
} from "../../../components";
import { useCoinInfo } from "../../../selectors";
import { queryTransaction, Transaction } from "../../../services";

const HistoryDetail: React.FC = () => {
  const { txHash, walletAddress } = useParams<{
    txHash: string;
    walletAddress: string;
  }>();

  const [data, setData] = useState<Transaction>();
  const coin = useCoinInfo(data?.coinType);
  useLayoutEffect(() => {
    queryTransaction(txHash, walletAddress).then((result) => {
      setData(result);
      console.log(result);
    });
  }, [txHash, walletAddress]);

  return (
    <Box>
      <Breadcrumbs style={{ marginTop: 32, marginBottom: 24 }}>
        <Link to="/wallet">
          <Box fontSize="h5.fontSize" color="text.secondary" component="span">
            Home
          </Box>
        </Link>
        <Link to={`/wallet/history/${walletAddress}`}>
          <Box fontSize="h5.fontSize" color="text.secondary" component="span">
            History
          </Box>
        </Link>
        <Box fontSize="h5.fontSize" color="primary.light">
          Transaction Details
        </Box>
      </Breadcrumbs>

      <Descriptions isPannel={true}>
        <DescriptionItem label="Hash">
          {data?.txHash && (
            <TxHash coinType={data.coinType} txHash={data.txHash}>
              {data.txHash}
            </TxHash>
          )}
        </DescriptionItem>
        <DescriptionItem label="Status">
          {data && <ChainStatus value={data?.txStatus} />}
        </DescriptionItem>
        {/* <DescriptionItem label="Block Height">
          {data?.}
        </DescriptionItem> */}
        <DescriptionItem label="Created Date">
          <Date value={data?.createdAt} />
        </DescriptionItem>
        <DescriptionItem label="From">
          <Box>
            <div className="flex flex-col">{data?.fromAddresses}</div>
          </Box>
        </DescriptionItem>
        <DescriptionItem label="To">
          <Box>
            <div className="flex flex-col">{data?.toAddresses}</div>
          </Box>
        </DescriptionItem>
        <DescriptionItem label="Value">
          {data?.balanceChange &&
            parseInt(data?.balanceChange) / Math.pow(10, coin?.decimal || 1)}
        </DescriptionItem>
        <DescriptionItem label="Fee">
          {data?.gas &&
            (parseInt(data?.gas) / Math.pow(10, coin?.decimal || 1)).toFixed(
              coin?.decimal || 10
            )}
        </DescriptionItem>
      </Descriptions>
    </Box>
  );
};

export default HistoryDetail;
