import { api } from "./ApiService";
import { Transaction } from "./Transaction";

export const ChainTypes = ["Bitcoin", "Ethereum"];
export interface WalletItem {
  address: string; // PartitionKey
  chainType: ChainType;
  coinType: string;
  userId: string; // GSI
  walletType: "HOT" | "COLD";
  blockId: number;
  currency: string[];
  name: string;
  createdAt: string;
  updatedAt: string;
  lastSyncBlockId: number;
  lastConfirmedTxTime: string;
  pendingChange: number;
  balance: number;
}

export interface Wallet {
  address: string;
  name: string;
  blockId: number;
  chainType: ChainType;
  coins: { [key: string]: { balance: number; pendingChange: number } };
  createAt: string;
  currency: "BTC" | "ETH";
  lastConfirmedTxTime: string;
  lastSyncBlockId: number;
  updateAt: string;
  userId: string;
  walletType: "HOT" | "COLD";
}

export const queryWallets = async (): Promise<Wallet[]> => {
  const res = await api.get<Wallet[]>("/wallets").then(({ data }) => {
    return data.map((item) => ({
      ...item,
      name: item.address.slice(0, 5),
    }));
  });
  return res;
};

export interface ExchangeRates {
  [coinType: string]: number;
}

export const queryExchangeRates = async (): Promise<ExchangeRates> => {
  console.info("Query Exchange Rates");

  return api
    .get("https://api.coinbase.com/v2/exchange-rates?currency=USD")
    .then(({ data }) => {
      const rates = data.data.rates;
      return {
        USDT: 1,
        DAI: 1 / Number(rates.DAI),
        ETH: 1 / Number(rates.ETH),
        BTC: 1 / Number(rates.BTC),
      };
    });
};

export const queryHistory = async (
  address: string,
  page: object | null
): Promise<{
  data: Transaction[];
  pagination: {
    next: object | null;
    total: number;
    pageSize: number;
  };
}> => {
  console.info("Query History");

  return api
    .get<{
      data: Transaction[];
      pagination: {
        next: object | null;
        total: number;
        pageSize: number;
      };
    }>(`/wallet/${address}/transactions`, {
      params: { ...page },
    })
    .then(({ data }) => data);
};

export type Fee = {
  average: number;
  fastest: number;
  safeLow: number;
};

export const queryGasEthFee = async (): Promise<Fee> => {
  const { data } = await api.get(`/eth/gasFee`);
  console.log("Getting Eth Gas Fee", data);
  const { fastest, average, safeLow } = data;
  return {
    average: average / 10,
    fastest: fastest / 10,
    safeLow: safeLow / 10,
  };
};

export const queryGasBtcFee = async (): Promise<Fee> => {
  const {
    data: { fastestFee, halfHourFee, hourFee },
  } = await api.get(`/btc/gasFee`);
  console.log("Getting BTC Gas Fee", fastestFee, halfHourFee, hourFee);
  return {
    average: halfHourFee,
    fastest: fastestFee,
    safeLow: hourFee,
  };
};

export const createWallet = async ({
  walletAddress,
  chainType,
}: {
  walletAddress?: string;
  chainType: ChainType;
}): Promise<any> => {
  console.info("Create Wallet");

  return api.post(`/${chainType}/wallet/${walletAddress ? "cold" : "hot"}`, {
    walletAddress,
  });
};

export const transfer = async (params: {
  amount: string;
  coinType: string;
  externalRef: string;
  gasPrice: string;
  description: string;
  fromAddress: string;
  toAddress: string;
  authorizationId?: string;
  customerId?: string;
}): Promise<any> => {
  const { coinType, ...rest } = params;
  console.info("Transfer");

  return api.post(`/${coinType.toLowerCase()}/transaction/withdraw`, {
    ...rest,
    txDirection: "OUT",
  });
};

export type ChainType = "bitcoin" | "ethereum";
