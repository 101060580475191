import React from "react";
import { Box, Spinner } from "../../components";

const LayoutSpinner: React.FC = () => {
  return (
    <Box mt="30vh" display="flex" justifyContent="center" alignItems="center">
      <Spinner />
    </Box>
  );
};

export default LayoutSpinner;
