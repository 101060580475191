import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  ButtonPrimary,
  FormControl,
  FormHelperText,
  InputText,
  MenuItem,
  Notification,
  Select,
} from "../../../components";
import { WALLET_TYPE } from "../../../constants";
import { useAddressMatch } from "../../../selectors";
import { ChainType, ChainTypes, createWallet } from "../../../services";

const WalletCreate: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [walletType, setWalletType] = useState<"HOT" | "COLD">("HOT");
  const [chainType, setChainType] = useState<any>("btc");
  const [walletAddress, setWalletAddress] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const addressMatch = useAddressMatch(chainType);

  const verifyAddress = useCallback(() => {
    if (addressMatch && walletAddress) {
      const matchResult = addressMatch.find((reg) =>
        new RegExp(reg).test(walletAddress)
      );
      if (!matchResult) {
        return false;
      }
    }
    return true;
  }, [addressMatch, walletAddress]);

  const addressVerifyMsg = useMemo(() => {
    if (isSubmitted) {
      if (!verifyAddress()) {
        return "Invalid address";
      }
    }
    return "";
  }, [isSubmitted, verifyAddress]);

  const handleSubmit = useCallback(async () => {
    setIsSubmitted(true);

    if (!verifyAddress()) return;

    setSubmitting(true);

    try {
      await createWallet({
        chainType,
        walletAddress: walletAddress,
      });

      Notification.success({
        message: "Create Wallet",
        description: "Success",
      });

      setSubmitting(false);
      history.push("/wallet");
    } catch {
      setSubmitting(false);
    }
  }, [chainType, walletAddress, history, verifyAddress]);

  return (
    <Box>
      <Box color="text.primary" fontSize="h5.fontSize" mt={4} mb={3}>
        {t("Create/Input a wallet")}
      </Box>
      <Box display="grid" gridGap={32} gridTemplateColumns="minmax(250px, 50%)">
        <Box>
          <Box fontSize="body1.fontSize" color="text.primary" mb={1}>
            Wallet Type
          </Box>
          <FormControl variant="outlined" fullWidth>
            <Select
              value={walletType}
              displayEmpty
              onChange={(events) =>
                setWalletType(events.target.value as "HOT" | "COLD")
              }
            >
              <MenuItem value={""} disabled>
                Select Wallet Type
              </MenuItem>
              {["HOT", "COLD"].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Box fontSize="body1.fontSize" color="text.primary" mb={1}>
            Coin Type
          </Box>
          <FormControl variant="outlined" fullWidth>
            <Select
              value={chainType}
              displayEmpty
              onChange={(events) =>
                setChainType(events.target.value as ChainType)
              }
            >
              <MenuItem value={""} disabled>
                Select Coin Type
              </MenuItem>
              <MenuItem value={"btc"}>Bitcoin</MenuItem>
              <MenuItem value={"eth"}>Ethereum</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {walletType === WALLET_TYPE[0][0] ? (
          <Box>
            <Box fontSize="body1.fontSize" color="text.primary" mb={1}>
              Cold Wallet Address (Must input your COLD wallet address)
            </Box>
            <FormControl
              error={!!addressVerifyMsg}
              variant="outlined"
              fullWidth
            >
              <InputText
                value={walletAddress}
                onChange={(event) => setWalletAddress(event.target.value)}
              />
              {addressVerifyMsg && (
                <FormHelperText>{addressVerifyMsg}</FormHelperText>
              )}
            </FormControl>
          </Box>
        ) : null}
        <Box display="grid" gridTemplateColumns="1fr 96px 96px" gridGap={24}>
          <Box></Box>
          <Link to="/wallet">
            <ButtonPrimary fullWidth variant="outlined">
              {t("Cancel")}
            </ButtonPrimary>
          </Link>
          <ButtonPrimary loading={submitting} onClick={handleSubmit}>
            {t("Confirm")}
          </ButtonPrimary>
        </Box>
      </Box>
    </Box>
  );
};

export default WalletCreate;
