import React from "react";
import { Route, Switch } from "react-router-dom";

import TransactionList from "./TransactionList";
import TransactionDetail from "./TransactionDetail";

const Transaction = () => {
  return (
    <Switch>
      <Route exact path="/transaction">
        <TransactionList />
      </Route>
      <Route path="/transaction/:txHash/:walletAddress">
        <TransactionDetail />
      </Route>
    </Switch>
  );
};

export default Transaction;
