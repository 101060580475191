import { api } from "./ApiService";

export interface Transaction {
  balanceChange: string;
  authorizationId: string;
  coinType: string;
  createdAt: string;
  createdBy: string;
  customerId: string;
  coinDecimal: string;
  coinContractAddress: string;
  description: string;
  externalRef: string;
  gas: string;
  fromAddresses: string[]; // GSI
  failedReason: string;
  gasPrice: string;
  gasLimit: string;
  recordedAt: string; // time included in block
  txStatus: string;
  signedRawTx: string;
  txHash: string; // primary key
  txIndex: string;
  toAddresses: string[]; // GSI
  txDirection: string;
  transactionType: string;
  userId: string; // GSI
  updatedAt: string;
  updatedBy: string;
  walletAddress: string;
}
export const queryTransactions = async ({
  page,
}: {
  page: object | null;
}): Promise<{
  data: Transaction[];
  pagination: {
    next: object | null;
    total: number;
    pageSize: number;
  };
}> => {
  console.info("Query Transactions");
  return (
    await api.get("/transactions", {
      params: { ...page },
    })
  ).data;
};

export const queryTransaction = async (
  txHash: string,
  walletAddress: string
): Promise<Transaction> => {
  console.info("Query Transaction");

  return (await api.get(`/transaction/${walletAddress}/${txHash}`)).data;
};
