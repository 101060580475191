import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box, ButtonPrimary, Divider } from "../../../components";
import { AddIcon } from "../../../icons";

const RenderCreateWallet: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Link to="/wallet/create">
        <ButtonPrimary
          size="large"
          classes={{
            root: classes.button,
          }}
        >
          <Box display="flex" alignItems="center" px={2}>
            <AddIcon />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            classes={{ root: classes.divider }}
            light
          />
          <Box px={3}>{t(`Create / Input`)}</Box>
        </ButtonPrimary>
      </Link>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    padding: "14px 0",
    borderRadius: "8px",
    boxShadow: "0 4px 10px 0 rgba(0, 10, 57, 0.36)",
    "&:hover": {
      boxShadow: "0 4px 10px 0 rgba(0, 10, 57, 0.36)",
    },
  },
  divider: {
    marginTop: -13,
    marginBottom: -13,
    background: theme.palette.common.white,
  },
}));

export default RenderCreateWallet;
