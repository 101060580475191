import React from "react";
import { BaseProps } from "../types";

const DaiIcon: React.FC<BaseProps> = ({ ...rest }) => {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" {...rest}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient">
          <stop stopColor="#FFA800" offset="0%"></stop>
          <stop stopColor="#FFC838" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-665.000000, -270.000000)">
          <g transform="translate(665.000000, 270.000000)">
            <circle fill="url(#linearGradient)" cx="24" cy="24" r="24"></circle>
            <path
              d="M23.9484749,12 C27.9976102,12 31.5140387,14.4317696 33.2831251,18.0002688 L37.5,18 L37.5,21 L34.2890062,21.0003936 C34.4273679,21.7272689 34.5,22.4795909 34.5,23.25 C34.5,23.5022252 34.4922149,23.7525117 34.476887,24.0006013 L37.5,24 L37.5,27 L33.8993573,27.0007265 C32.4506641,31.3698184 28.5424205,34.5 23.9484749,34.5 L23.9484749,34.5 L15,34.5 L15,27 L12,27 L12,24 L15,24 L15,21 L12,21 L12,18 L15,18 L15,12 Z M31.3426021,27.0010401 L17.364,27 L17.3648689,31.9784483 L23.9484749,31.9784483 C27.2103982,31.9784483 30.0267406,29.944402 31.3426021,27.0010401 Z M31.8606216,21.0007759 L17.364,21 L17.364,24 L32.1051803,24.00029 C32.1180794,23.8387474 32.1268519,23.67595 32.1313849,23.512018 L32.135003,23.25 C32.135003,22.4723119 32.0396109,21.7183786 31.8606216,21.0007759 Z M23.9484749,14.5215517 L17.3648689,14.5215517 L17.364,18 L30.4889073,17.9996292 C29.0407361,15.9521234 26.7663797,14.6062619 24.1942251,14.5254093 L23.9484749,14.5215517 Z"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DaiIcon;
