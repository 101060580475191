import React, { useLayoutEffect, useState, useMemo } from "react";
import { useFetchAccount, useFetchWallets } from "../../../common";
import { Box } from "../../../components";
import { ExchangeRates, queryExchangeRates } from "../../../services";
import RenderCreateWallet from "./RenderCreateWallet";
import RenderWalletBalance from "./RenderWalletBalance";
import RenderWallets from "./RenderWallets";

const Home: React.FC = () => {
  const { data: wallets, fetcher } = useFetchWallets();
  const [rates, setRates] = useState<ExchangeRates>();

  useLayoutEffect(() => {
    fetcher();
  }, [fetcher]);

  useLayoutEffect(() => {
    queryExchangeRates().then((result) => {
      setRates(result);
    });
  }, []);

  const total = useMemo(() => {
    if (!wallets) return;

    const reduced = wallets.reduce<{ [coinType: string]: number }>(
      (acc, curr) => {
        curr.coins &&
          Object.entries(curr.coins).forEach((entry) => {
            const [cT, balanceInfo] = entry;
            const coinType = cT.toLowerCase();
            if (acc[coinType])
              acc[coinType] = acc[coinType] + balanceInfo.balance;
            else acc[coinType] = balanceInfo.balance;
          });
        return acc;
      },
      {}
    );

    return reduced;
  }, [wallets]);
  const { data } = useFetchAccount();

  return (
    <Box display="grid" gridGap={24} py={3}>
      {data && (data as any).data.roleId === "admin" && <RenderCreateWallet />}
      <RenderWalletBalance data={total} rates={rates} />
      <RenderWallets wallets={wallets} />
    </Box>
  );
};

export default Home;
