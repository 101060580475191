import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { Box, Spinner } from "../../components";
import "antd/dist/antd.css";

import styles from "antd";
import {
  AuthorizationStep,
  queryAuthorizationRequests,
  queryAuthorizationSteps,
} from "../../services/Authorization";
import AuthorizationTable from "./AuthorizationTable";
const { TabPane } = Tabs;
const Authorization = () => {
  const [pendingData, setPendingData] = useState<AuthorizationStep[]>();
  const [requestData, setRequestData] = useState<AuthorizationStep[]>();

  useEffect(() => {
    queryAuthorizationSteps().then((steps) => {
      setPendingData((data) => steps);
    });
    queryAuthorizationRequests().then((steps) => {
      setRequestData((data) => steps);
    });
  }, []);

  return (
    <Box>
      <Box color="text.primary" fontSize="h5.fontSize" mt={4} mb={3}>
        Pending Actions
      </Box>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Pending Action" key="1">
          {pendingData && <AuthorizationTable actionable data={pendingData} />}
        </TabPane>
        <TabPane tab="Awaiting Approval" key="2">
          {requestData && <AuthorizationTable data={requestData} />}
        </TabPane>
      </Tabs>
    </Box>
  );
};

export default Authorization;
