import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  "@global": {
    ".ant-table-middle .ant-table-title, .ant-table-middle .ant-table-footer, .ant-table-middle .ant-table-thead > tr > th, .ant-table-middle .ant-table-tbody > tr > td": {
      padding: "12px 8px",
    },
    ".ant-table-middle .ant-table-thead th.ant-table-column-has-sorters": {
      padding: "0",
    },
    ".ant-table-middle .ant-table-thead .ant-table-filter-column": {
      margin: "-12px -8px",
    },
    ".ant-table-middle .ant-table-thead .ant-table-filter-column-title": {
      padding: "12px 2.3em 12px 8px",
    },
    ".ant-table-middle .ant-table-thead .ant-table-column-sorters": {
      padding: "12px 8px",
    },
    ".ant-table-middle .ant-table-expanded-row-fixed": {
      margin: "-12px -8px",
    },
    ".ant-table-small .ant-table-title, .ant-table-small .ant-table-footer, .ant-table-small .ant-table-thead > tr > th, .ant-table-small .ant-table-tbody > tr > td": {
      padding: "8px 8px",
    },
    ".ant-table-small .ant-table-thead th.ant-table-column-has-sorters": {
      padding: "0",
    },
    ".ant-table-small .ant-table-thead .ant-table-filter-column": {
      margin: "-8px -8px",
    },
    ".ant-table-small .ant-table-thead .ant-table-filter-column-title": {
      padding: "8px 2.3em 8px 8px",
    },
    ".ant-table-small .ant-table-thead .ant-table-column-sorters": {
      padding: "8px 8px",
    },
    ".ant-table-small .ant-table-expanded-row-fixed": {
      margin: "-8px -8px",
    },
    ".ant-table-small .ant-table-thead > tr > th": {
      // backgroundColor: "#fafafa",
    },
    ".ant-table.ant-table-bordered .ant-table-title": {
      border: "1px solid rgba(0, 0, 0, 0.06)",
      borderBottom: "0",
    },
    ".ant-table.ant-table-bordered thead > tr > th, .ant-table.ant-table-bordered tbody > tr > td, .ant-table.ant-table-bordered tfoot > tr > th, .ant-table.ant-table-bordered tfoot > tr > td": {
      borderRight: "1px solid rgba(0, 0, 0, 0.06)",
    },
    ".ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after": {
      borderRight: "1px solid rgba(0, 0, 0, 0.06)",
    },
    ".ant-table.ant-table-bordered table thead > tr:not(:last-child) > th": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
    },
    ".ant-table.ant-table-bordered .ant-table-container": {
      border: "1px solid rgba(0, 0, 0, 0.06)",
      borderRight: "0",
      borderBottom: "0",
    },
    ".ant-table.ant-table-bordered .ant-table-expanded-row-fixed": {
      margin: "-16px -17px",
    },
    ".ant-table.ant-table-bordered .ant-table-expanded-row-fixed::after": {
      position: "absolute",
      top: "0",
      right: 1,
      bottom: "0",
      borderRight: "1px solid rgba(0, 0, 0, 0.06)",
      content: '""',
    },
    ".ant-table.ant-table-bordered.ant-table-scroll-horizontal\n  tr.ant-table-expanded-row\n  > td, .ant-table.ant-table-bordered.ant-table-scroll-horizontal\n  tr.ant-table-placeholder\n  > td": {
      borderRight: "0",
    },
    ".ant-table.ant-table-bordered.ant-table-middle .ant-table-expanded-row-fixed": {
      margin: "-12px -9px",
    },
    ".ant-table.ant-table-bordered.ant-table-small .ant-table-expanded-row-fixed": {
      margin: "-8px -9px",
    },
    ".ant-table.ant-table-bordered .ant-table-footer": {
      border: "1px solid rgba(0, 0, 0, 0.06)",
      borderTop: "0",
    },
    ".ant-table-wrapper::before": {
      display: "table",
      content: '""',
    },
    ".ant-table-wrapper::after": {
      display: "table",
      clear: "both",
      content: '""',
    },
    ".ant-table": {
      boxSizing: "border-box",
      margin: "0",
      padding: "0",
      color: theme.palette.text.primary,
      fontSize: 14,
      wordBreak: "break-word",
      fontVariant: "tabular-nums",
      listStyle: "none",
      fontFeatureSettings: '"tnum"',
      position: "relative",
      zIndex: "0",
      clear: "both",
      background: "#fff",
      borderRadius: 2,
    },
    ".ant-table table": {
      width: "100%",
      textAlign: "left",
      borderRadius: "2px 2px 0 0",
      borderCollapse: "separate",
      borderSpacing: "0",
    },
    ".ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td": {
      position: "relative",
      padding: "16px 16px",
      overflowWrap: "break-word",
    },
    ".ant-table-cell-ellipsis": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      wordBreak: "keep-all",
    },
    ".ant-table-cell-ellipsis.ant-table-cell-fix-left-last, .ant-table-cell-ellipsis.ant-table-cell-fix-right-first": {
      overflow: "visible",
    },
    ".ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content, .ant-table-cell-ellipsis.ant-table-cell-fix-right-first\n  .ant-table-cell-content": {
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    ".ant-table-title": {
      padding: "16px 16px",
      borderRadius: "2px 2px 0 0",
    },
    ".ant-table-footer": {
      padding: "16px 16px",
      color: theme.palette.text.primary,
      // background: "#fafafa",
      borderRadius: "0 0 2px 2px",
    },
    ".ant-table-thead > tr > th": {
      whiteSpace: "nowrap",
      color: theme.palette.text.secondary,
      fontSize: 12,
      fontWeight: "500",
      textAlign: "left",
      // background: "#fafafa",
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
      transition: "background 0.3s ease",
    },
    '.ant-table-thead > tr > th[colspan]:not([colspan="1"])': {
      textAlign: "center",
    },
    ".ant-table-thead > tr:not(:last-child) > th[colspan]": {
      borderBottom: "0",
    },
    ".ant-table-tbody > tr > td": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
      transition: "background 0.3s",
    },
    ".ant-table-tbody > tr.ant-table-row-selected > td": {
      background: "#e6f7ff",
    },
    ".ant-table-tbody > tr.ant-table-row-selected :hover > td": {
      background: "#e6f7ff",
    },
    ".ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table": {
      margin: "-16px -16px -16px 33px",
    },
    ".ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table td": {
      background: "transparent",
    },
    ".ant-table-tbody\n  > tr\n  .ant-table-wrapper:only-child\n  .ant-table-tbody\n  > tr:last-child\n  > td": {
      borderBottom: "0",
    },
    ".ant-table-tbody\n  > tr\n  .ant-table-wrapper:only-child\n  .ant-table-tbody\n  > tr:last-child\n  > td:first-child, .ant-table-tbody\n  > tr\n  .ant-table-wrapper:only-child\n  .ant-table-tbody\n  > tr:last-child\n  > td:last-child": {
      borderRadius: "0",
    },
    ".ant-table tfoot > tr > th, .ant-table tfoot > tr > td": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
    },
    ".ant-table-pagination.ant-pagination": {
      margin: "16px 0",
    },
    ".ant-table-pagination-left": {
      float: "left",
    },
    ".ant-table-pagination-center": {
      textAlign: "center",
    },
    ".ant-table-pagination-right": {
      float: "right",
    },
    ".ant-table-thead th.ant-table-column-has-sorters": {
      padding: "0",
      cursor: "pointer",
      transition: "all 0.3s",
    },
    ".ant-table-thead th.ant-table-column-has-sorters:hover": {
      // background: "#f2f2f2",
    },
    ".ant-table-thead\n  th.ant-table-column-has-sorters:hover\n  .ant-table-filter-trigger-container": {
      // background: "#f7f7f7",
    },
    ".ant-table-thead th.ant-table-column-sort": {
      // background: "#f5f5f5",
    },
    "td.ant-table-column-sort": {
      // background: "#fafafa",
    },
    ".ant-table-column-sorters-with-tooltip": {
      display: "inline-block",
      width: "100%",
    },
    ".ant-table-column-sorters": {
      display: "inline-flex",
      alignItems: "center",
      padding: "16px 16px",
    },
    ".ant-table-column-sorter": {
      marginTop: "0.15em",
      marginBottom: "-0.15em",
      marginLeft: 8,
      color: "#bfbfbf",
    },
    ".ant-table-column-sorter-full": {
      marginTop: "-0.2em",
      marginBottom: "0",
    },
    ".ant-table-column-sorter-inner": {
      display: "inline-flex",
      flexDirection: "column",
      alignItems: "center",
    },
    ".ant-table-column-sorter-up, .ant-table-column-sorter-down": {
      display: "inline-block",
      fontSize: 11,
    },
    ".ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active": {
      color: "#1890ff",
    },
    ".ant-table-column-sorter-up + .ant-table-column-sorter-down": {
      marginTop: "-0.3em",
    },
    ".ant-table colgroup > col.ant-table-selection-col": {
      width: 60,
    },
    "table tr th.ant-table-selection-column, table tr td.ant-table-selection-column": {
      textAlign: "center",
    },
    "table tr th.ant-table-selection-column .ant-radio-wrapper, table tr td.ant-table-selection-column .ant-radio-wrapper": {
      marginRight: "0",
    },
    ".ant-table-selection": {
      position: "relative",
    },
    ".ant-table-selection-extra": {
      position: "absolute",
      top: "0",
      right: -10,
      cursor: "pointer",
      transition: "all 0.3s",
    },
    ".ant-table-selection-extra .anticon": {
      display: "inline-block",
      fontSize: 10,
      color: "#bfbfbf",
    },
    ".ant-table-selection-extra .anticon:hover": {
      color: "#a6a6a6",
    },
    ".ant-table-expand-icon-col": {
      width: 48,
    },
    ".ant-table-row-expand-icon-cell": {
      textAlign: "center",
    },
    ".ant-table-row-indent": {
      float: "left",
      height: 1,
    },
    ".ant-table-row-expand-icon": {
      color: "inherit",
      textDecoration: "none",
      cursor: "pointer",
      transition: "all 0.3s",
      position: "relative",
      display: "inline-flex",
      float: "left",
      boxSizing: "border-box",
      width: 17,
      height: 17,
      padding: "0",
      fallbacks: [
        {
          transition: "color 0.3s",
        },
        {
          color: "#1890ff",
        },
      ],
      lineHeight: 12,
      verticalAlign: -2,
      background: "#fff",
      border: "1px solid rgba(0, 0, 0, 0.06)",
      borderRadius: 2,
      outline: "none",
      userSelect: "none",
    },
    ".ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover": {
      color: "#40a9ff",
    },
    ".ant-table-row-expand-icon:active": {
      color: "#096dd9",
    },
    ".ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover, .ant-table-row-expand-icon:active": {
      borderColor: "currentColor",
    },
    ".ant-table-row-expand-icon::before, .ant-table-row-expand-icon::after": {
      position: "absolute",
      background: "currentColor",
      transition: "transform 0.3s ease-out",
      content: '""',
    },
    ".ant-table-row-expand-icon::before": {
      top: 7,
      right: 3,
      left: 3,
      height: 1,
    },
    ".ant-table-row-expand-icon::after": {
      top: 3,
      bottom: 3,
      left: 7,
      width: 1,
      transform: "rotate(90deg)",
    },
    ".ant-table-row-expand-icon-collapsed::before": {
      transform: "rotate(-180deg)",
    },
    ".ant-table-row-expand-icon-collapsed::after": {
      transform: "rotate(0deg)",
    },
    ".ant-table-row-expand-icon-spaced": {
      background: "transparent",
      border: "0",
    },
    ".ant-table-row-expand-icon-spaced::before, .ant-table-row-expand-icon-spaced::after": {
      display: "none",
      content: "none",
    },
    ".ant-table-row-indent + .ant-table-row-expand-icon": {
      marginTop: 2.5005,
      marginRight: 8,
    },
    "tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td": {
      background: "#fbfbfb",
    },
    ".ant-table .ant-table-expanded-row-fixed": {
      position: "relative",
      margin: "-16px -16px",
      padding: "16px 16px",
    },
    ".ant-table-tbody > tr.ant-table-placeholder": {
      textAlign: "center",
    },
    ".ant-table-tbody > tr.ant-table-placeholder:hover > td": {
      background: "#fff",
    },
    ".ant-table-cell-fix-left, .ant-table-cell-fix-right": {
      zIndex: "2",
      background: "#fff",
    },
    ".ant-table-cell-fix-left-first::after, .ant-table-cell-fix-left-last::after": {
      position: "absolute",
      top: "0",
      right: "0",
      bottom: -1,
      width: 30,
      transform: "translateX(100%)",
      transition: "box-shadow 0.3s",
      content: '""',
      pointerEvents: "none",
    },
    ".ant-table-cell-fix-right-first::after, .ant-table-cell-fix-right-last::after": {
      position: "absolute",
      top: "0",
      bottom: -1,
      left: "0",
      width: 30,
      transform: "translateX(-100%)",
      transition: "box-shadow 0.3s",
      content: '""',
      pointerEvents: "none",
    },
    ".ant-table .ant-table-container::before, .ant-table .ant-table-container::after": {
      position: "absolute",
      top: "0",
      bottom: "0",
      zIndex: "1",
      width: 30,
      transition: "box-shadow 0.3s",
      content: '""',
      pointerEvents: "none",
    },
    ".ant-table .ant-table-container::before": {
      left: "0",
    },
    ".ant-table .ant-table-container::after": {
      right: "0",
    },
    ".ant-table-title + .ant-table-container": {
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
    },
    ".ant-table-title\n  + .ant-table-container\n  table\n  > thead\n  > tr:first-child\n  th:first-child": {
      borderRadius: "0",
    },
    ".ant-table-title\n  + .ant-table-container\n  table\n  > thead\n  > tr:first-child\n  th:last-child": {
      borderRadius: "0",
    },
    ".ant-table-container": {
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
    },
    ".ant-table-container table > thead > tr:first-child th:first-child": {
      borderTopLeftRadius: 2,
    },
    ".ant-table-container table > thead > tr:first-child th:last-child": {
      borderTopRightRadius: 2,
    },
  },
  root: {},
  isPannel: {
    // "& .ant-table": {
    //   border: `1px solid ${theme.palette.common.greyBorder}`,
    //   borderRadius: theme.spacing(1),
    // },
    "& .ant-table .MuiButtonBase-root": {
      whiteSpace: "nowrap",
    },
    // "& table > thead > tr:first-child th:first-child": {
    //   borderTopLeftRadius: theme.spacing(1),
    // },
    // "& table > thead > tr:first-child th:last-child": {
    //   borderTopRightRadius: theme.spacing(1),
    // },
    // "& table > tbody > tr:last-child td:first-child": {
    //   borderBottomLeftRadius: theme.spacing(1),
    // },
    // "& table > tbody > tr:last-child td:last-child": {
    //   borderBottomRightRadius: theme.spacing(1),
    // },
    "& table > tbody > tr:last-child td": {
      borderBottom: 0,
    },
  },
  hasHover: {
    "& .ant-table-tbody > tr.ant-table-row:hover > td": {
      background: "#f2f5f7",
    },
  },
}));

export default useStyle;
