import { Paper } from "@material-ui/core";

import React from "react";

type PannelProps = React.ComponentProps<typeof Paper>;

const Pannel: React.FC<PannelProps> = ({ ...rest }) => {
  return <Paper elevation={0} {...rest} />;
};

export default Pannel;
