import AntdDescriptions from "antd/es/descriptions";
import React from "react";

const AntdDescriptionItem = AntdDescriptions.Item;

type DescriptionItemProps = React.ComponentProps<typeof AntdDescriptionItem>;

const DescriptionItem: React.FC<DescriptionItemProps> = ({ ...rest }) => {
  return <AntdDescriptionItem {...rest} />;
};

export default DescriptionItem;
