import { useMemo } from "react";
import { useFetchAllCoins } from "../common";
import { TOKEN_NAMES } from "../constants";
import { Coin } from "../services";

export const useCoins = (): Coin[] => {
  const {
    data: { data },
  } = useFetchAllCoins() as any;
  return useMemo(() => {
    return data?.map((c: Coin) => ({ ...c }));
  }, [data]);
};

export const useCoinInfo = (coinType?: string) => {
  const coins = useCoins();

  return useMemo(() => {
    if (!coinType) return;
    if (!coins) return;

    return coins.find(({ shortName }) => {
      return shortName === coinType;
    });
  }, [coins, coinType]);
};

export const useAddressMatch = (coinName: string) => {
  const {
    data: { data },
  } = useFetchAllCoins() as any;

  return useMemo(() => {
    const reg = data?.find(({ shortName }: Coin) => {
      return coinName === shortName.toLowerCase();
    })?.addressRegex;

    if (!reg) return;

    try {
      return JSON.parse(reg) as string[];
    } catch {
      return;
    }
  }, [coinName, data]);
};
