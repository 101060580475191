import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFetchWallets } from "../../../common";
import { Box } from "../../../components";
import RenderWalletItem from "../RenderWalletItem";
import RenderTransactionCreateForm from "./RenderTransactionCreateForm";

const TransactionCreate = () => {
  const { t } = useTranslation();
  const { walletAddress } = useParams<{
    walletAddress: string;
  }>();

  const { data: wallets } = useFetchWallets();

  const wallet = useMemo(() => {
    return wallets?.find(({ address }) => address === walletAddress);
  }, [walletAddress, wallets]);

  return (
    <Box>
      <Box color="text.primary" fontSize="h5.fontSize" mt={4} mb={3}>
        {t("Create a Transaction")}
      </Box>
      {wallet && (
        <Box display="grid" mb={6}>
          <RenderWalletItem
            //assets={balancesMap?.[walletInfo.address.toLowerCase()]}
            wallet={wallet}
            hideAction={true}
          />
        </Box>
      )}
      {wallet && <RenderTransactionCreateForm wallet={wallet} />}
    </Box>
  );
};

export default TransactionCreate;
