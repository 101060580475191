import React, { useLayoutEffect } from "react";
import { useFetchAllCoins } from "../../common";
import LayoutSpinner from "./LayoutSpinner";

const useInitCoins = () => {
  const { data, fetcher } = useFetchAllCoins();

  useLayoutEffect(() => {
    fetcher();
  }, [fetcher]);

  return data;
};

const Init: React.FC = ({ children }) => {
  const coins = useInitCoins();

  const isLoading = !coins;

  return (
    <React.Fragment>{isLoading ? <LayoutSpinner /> : children}</React.Fragment>
  );
};

export default Init;
