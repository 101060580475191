import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const CssBaseline: React.FC = (props) => {
  const { children = null } = props;

  useStyles();

  return <React.Fragment>{children}</React.Fragment>;
};

export const body = (theme: Theme) => ({
  color: theme.palette.text.primary,
  ...theme.typography.body2,
  "@media print": {
    // Save printer ink.
    backgroundColor: theme.palette.common.white,
  },
});

const useStyles = makeStyles((theme) => ({
  "@global": {
    html: {
      boxSizing: "border-box",
    },
    "*, *::before, *::after": {
      boxSizing: "border-box",
    },
    "strong, b": {
      fontWeight: theme.typography.fontWeightBold,
    },
    a: {
      color: "#3273dc",
      textDecoration: "none",
    },
    th: {
      textAlign: "inherit",
    },
    td: {
      textAlign: "inherit",
    },
    table: {
      borderCollapse: "collapse",
    },
    body: {
      margin: 0,
      ...body(theme),
      "&::backdrop": {
        backgroundColor: theme.palette.background.default,
      },
    },
    "@keyframes loadingCircle": {
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  },
}));

export default CssBaseline;
