import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Box } from "../../components";
import Auth from "./Auth";
import Init from "./Init";
import Footer from "./Footer";
import Header from "./Header";

const Layout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const match = useRouteMatch({
    path: "/login",
    exact: true,
  });

  const main = match ? (
    children
  ) : (
    <Auth>
      <Init>{children}</Init>
    </Auth>
  );

  return (
    <div>
      <Header />
      <Box maxWidth={1280} px={2} mx="auto">
        {main}
      </Box>
      <Footer />
    </div>
  );
};

export default Layout;
