import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Helvetica",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
    ].join(","),
  },
  palette: {
    primary: {
      light: "#3273dc",
      main: "#0155ff",
      dark: "#0050f3",
    },
    common: {
      greyBorder: "#dadce0",
      linkHover: "#1741d4",
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiOutlinedInput: {
      notched: false,
    },
  },
  overrides: {
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiChip: {
      root: {
        height: undefined,
        borderRadius: 8,
        verticalAlign: undefined,
      },
    },
    MuiSelect: {
      outlined: {
        paddingTop: 11,
        paddingBottom: 11,
      },
    },
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: undefined,
        },
        "&$focused $notchedOutline": {
          boxShadow: "rgba(1, 85, 255, 0.25) 0 0 0 0.2rem",
        },
      },
    },
    MuiBreadcrumbs: {
      root: {
        lineHeight: 1.43,
      },
      separator: {
        marginLeft: 16,
        marginRight: 16,
      },
    },
    MuiIconButton: {
      root: {
        padding: 4,
      },
    },
    MuiFormLabel: {
      root: {
        color: "text.primary",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
        border: "1px solid #dadce0",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        "&$disabled": {
          color: null,
        },
        "&:hover": {
          "&$disabled": {
            backgroundColor: null,
          },
        },
      },
      containedPrimary: {
        "&:active": {
          backgroundColor: "#004ce7",
        },
      },
      contained: {
        boxShadow: "none",
        "&$disabled": {
          cursor: "not-allowed",
          opacity: 0.5,
          borderColor: "transparent",
          boxShadow: "none",
          backgroundColor: null,
          color: null,
        },
        "&:hover": {
          boxShadow: "none",
          "&$disabled": {
            backgroundColor: null,
          },
        },
        "&:active": {
          boxShadow: "none",
        },
      },
    },
  },
});

export default theme;
