import { api } from "./ApiService";

export interface Coin {
  addressRegex: string;
  addressUrl: string;
  balanceTransfer: number;
  balanceTransferred: boolean;
  balanceWarned: boolean;
  balanceWarning: number;
  decimal: number;
  chainAssetsId: string;
  chainAssetsType: string;
  createdAt: string;
  depositConfirmations: number;
  gas: number;
  gasLimit: number;
  gasPrice: number;
  id: number;
  isDeposit: boolean;
  isWithdraw: boolean;
  name: string;
  networkUrl: string;
  parentCoinId: number;
  shortName: string;
  signworkUrl: string;
  status: number;
  transactionUrl: string;
  updatedAt: string;
  version: number;
  withdrawConfirmations: number;
}

export const queryAllCoins = async (): Promise<Coin[]> => {
  return api.get("/api/coins");
};
