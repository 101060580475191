import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useFetchWallets } from "../../../common";
import {
  Address,
  AssetName,
  Box,
  Breadcrumbs,
  ButtonPrimary,
  ChainStatus,
  Chip,
  Date,
  DescriptionItem,
  Descriptions,
  Ellipsis,
  Table,
  TxHash,
} from "../../../components";
import { useCoins } from "../../../selectors";
import { queryHistory, Transaction } from "../../../services";

const TransactionList: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { walletAddress } = useParams<{
    walletAddress: string;
  }>();

  const [walletHistory, setWalletHistory] = useState<Transaction[]>();
  const { data: wallets, fetcher } = useFetchWallets();
  const [nextPage, setNextPage] = useState<object | null>(null);
  const [sorter, setSorter] = useState<string>();
  const coins = useCoins();
  console.log(coins);
  useLayoutEffect(() => {
    fetcher();
  }, [fetcher]);

  const goNextPage = (page?: object | null) => {
    queryHistory(walletAddress, page !== undefined ? page : nextPage).then(
      ({ data, pagination }) => {
        setNextPage(pagination.next);
        setWalletHistory((walletHistory) => data);
      }
    );
  };

  useLayoutEffect(() => {
    if (walletAddress) {
      queryHistory(walletAddress, nextPage).then((result) => {
        setNextPage(result.pagination.next);
        setWalletHistory(result.data);
      });
    }
  }, []);

  const walletInfo = useMemo(() => {
    return wallets?.find(({ address }) => address === walletAddress);
  }, [wallets, walletAddress]);

  const columns = useMemo<any>(() => {
    return [
      {
        title: "Status",
        dataIndex: "txStatus",
        render: (value: string) => <ChainStatus value={value} />,
      },
      {
        title: "Tx Hash",
        dataIndex: "txHash",
        render: (value: string, { coinType }: { coinType: string }) => {
          return (
            <TxHash coinType={coinType} txHash={value}>
              <Ellipsis maxWidth={130}>{value}</Ellipsis>
            </TxHash>
          );
        },
      },
      {
        title: "From",
        dataIndex: "fromAddresses",
        render: (fromAddresses: { [index: number]: string } | undefined) => {
          console.log(fromAddresses);
          return (
            <div className="flex flex-col">
              {fromAddresses &&
                Object.values(fromAddresses).map((addr) => (
                  <Ellipsis maxWidth={130}>{addr}</Ellipsis>
                ))}
            </div>
          );
        },
      },
      {
        title: "To",
        dataIndex: "toAddresses",
        render: (toAddresses: { [index: number]: string } | undefined) => {
          console.log(toAddresses);
          return (
            <div className="flex flex-col">
              {toAddresses &&
                Object.values(toAddresses).map((addr) => (
                  <Ellipsis maxWidth={130}>{addr}</Ellipsis>
                ))}
            </div>
          );
        },
      },
      {
        title: "",
        dataIndex: "txDirection",
        render: (value: string) => {
          return (
            <Chip
              label={value}
              className={
                value === "OUT" ? classes.warningBg : classes.successBg
              }
            />
          );
        },
      },
      {
        title: "Currency",
        sorter: true,
        dataIndex: "coinType",
        render: (value: string) => <AssetName coinType={value} />,
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        render: (value: string) => <Date value={value} />,
      },
      {
        title: "Amount",
        dataIndex: "balanceChange",
        render: (value: number, { txDirection, coinType }: Transaction) => {
          const coin = coins.find((c) => c.shortName === coinType);
          return (
            value &&
            `${txDirection === "IN" ? "+" : ""}${
              value / Math.pow(10, coin?.decimal || 0)
            }`
          );
        },
      },
      {
        title: "",
        dataIndex: "txHash",
        align: "right",
        render: (txHash: string) => (
          <Link to={`/wallet/transaction/${txHash}/${walletAddress}`}>
            <ButtonPrimary>{t("View")}</ButtonPrimary>
          </Link>
        ),
      },
    ];
  }, [classes.successBg, classes.warningBg, t, walletAddress]);

  const onTableChange = useCallback(({ current }, _, { field, order }) => {
    if (!field || !order) {
      setSorter(undefined);
    } else {
      setSorter(
        `${field},${
          order === "ascend" ? "asc" : order === "descend" ? "desc" : undefined
        }`
      );
    }
  }, []);

  return (
    <Box>
      <Breadcrumbs style={{ marginTop: 32, marginBottom: 24 }}>
        <Link to="/wallet">
          <Box fontSize="h5.fontSize" color="text.secondary" component="span">
            {t("Home")}
          </Box>
        </Link>
        <Box fontSize="h5.fontSize" color="primary.light">
          History
        </Box>
      </Breadcrumbs>
      <Descriptions
        isPannel={true}
        style={{ marginBottom: 24, maxWidth: 480, marginLeft: -8 }}
      >
        <DescriptionItem label="Address">
          <Address chainType={walletInfo?.chainType} address={walletAddress}>
            {walletAddress}
          </Address>
        </DescriptionItem>
      </Descriptions>
      <Table
        columns={columns}
        isPannel={true}
        rowKey="txHash"
        scroll={{
          x: true,
        }}
        dataSource={walletHistory}
        onChange={onTableChange}
        pagination={false}
      />
      <div
        style={{
          flexDirection: "row-reverse",
          width: "100%",
          display: "flex",
          marginTop: "1rem",
        }}
      >
        <ButtonPrimary
          disabled={walletHistory && walletHistory.length < 50}
          onClick={() => goNextPage()}
        >
          Next Page
        </ButtonPrimary>
        <ButtonPrimary
          style={{ backgroundColor: "gray", marginRight: "1rem" }}
          onClick={() => {
            goNextPage(null);
          }}
        >
          First Page
        </ButtonPrimary>
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  warningBg: {
    background: theme.palette.warning.main,
    color: theme.palette.common.white,
  },
  successBg: {
    background: theme.palette.success.main,
    color: theme.palette.common.white,
  },
}));

export default TransactionList;
