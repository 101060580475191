import numbro from "numbro";
import React from "react";
import { Box } from "../";

type NumberFormatProps = {
  percent?: boolean;
  options?: Record<string, any>;
  mantissa?: number;
  value?: number | string;
  prefix?: string;
  postfix?: string;
  placeholder?: string;
  thousandSeparated?: boolean;
} & React.ComponentProps<typeof Box>;

const NumberFormat: React.FC<NumberFormatProps> = ({
  value,
  percent = false,
  mantissa = 3,
  prefix = "",
  postfix = "",
  placeholder = "---",
  thousandSeparated = true,
  ...rest
}) => {
  const Default = <Box {...rest}>{placeholder}</Box>;
  if (value === undefined || isNaN(value as number) || value === null)
    return Default;

  const formatOptions: Record<string, any> = {};

  if (percent) {
    formatOptions.output = "percent";
  }

  formatOptions.mantissa = mantissa;

  formatOptions.thousandSeparated = thousandSeparated;

  const formatValue = numbro(value).format(formatOptions);

  if (typeof formatValue === "string" && !formatValue.includes("ERROR")) {
    return <Box {...rest}>{`${prefix}${formatValue}${postfix}`}</Box>;
  } else {
    return Default;
  }
};

export default NumberFormat;
