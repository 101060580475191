import { api } from "./ApiService";

export const queryToken = async (
  username: string,
  password: string,
  rememberMe = false
): Promise<string> => {
  return api
    .post("/api/authenticate", {
      username,
      password,
      rememberMe,
    })
    .then(({ data }) => {
      return data.token;
    });
};

export interface Account {
  activated: boolean;
  authorities: string[];
  createdBy: string;
  createdDate: string;
  email: string;
  firstName: string;
  id: number;
  imageUrl: string;
  langKey: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  lastName: string;
  login: string;
}

export const queryAccount = async (): Promise<Account> => {
  return api.get("/api/account");
};
