import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { Box } from "../";

type EllipsisProps = React.ComponentProps<typeof Box>;

const Ellipsis: React.FC<EllipsisProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return <Box className={clsx(classes.root, className)} {...rest} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
    display: "inline-block",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
  },
}));

export default Ellipsis;
