import { Box } from "@material-ui/core";
import React from "react";

type AssetNameProps = {
  coinType: string;
} & React.ComponentProps<typeof Box>;

const AssetName: React.FC<AssetNameProps> = ({ coinType, ...rest }) => {
  return <Box {...rest}>{coinType?.toUpperCase()}</Box>;
};

export default AssetName;
