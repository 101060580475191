import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Box, ButtonPrimary, InputLabel, InputText } from "../../components";
import { Logo } from "../../icons";
import { queryToken, setAuthToken } from "../../services";

const Login: React.FC = (event) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const login = useCallback(
    async (event) => {
      event.preventDefault();

      if (!username || !password) return false;

      setLoading(true);

      try {
        const token = await queryToken(username, password);
        setAuthToken(token);
        history.push("/wallet");
      } catch {
        setLoading(false);
      }
    },
    [history, username, password]
  );

  return (
    <Box
      mt="15vh"
      display="grid"
      gridGap={32}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      component="form"
    >
      <Box display="flex" justifyContent="center">
        <Logo style={{ height: 64 }} />
      </Box>
      <Box width={480}>
        <InputLabel>{t("Username")}</InputLabel>
        <InputText
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          fullWidth={true}
          placeholder={t("Enter your username")}
        />
      </Box>
      <Box>
        <InputLabel>{t("Password")}</InputLabel>
        <InputText
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          type="password"
          fullWidth={true}
          placeholder={t("Enter your Password")}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <ButtonPrimary
          type="submit"
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={login}
          loading={loading}
        >
          {t("Login")}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

export default Login;
